@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.background {
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  background-color: #262729;
  flex: 1 0 auto;

  align-items: center;
}

.backgroundForm {
  width: 40vw;

  background-color: #18191c;
  padding: 2rem 0;

  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.innerBlock {
  width: 75%;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #b8b8b8;
  text-align: center;
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.layoutFooter {
  width: 100%;
  height: 10vh;

  overflow-y: auto;
  overflow-x: hidden;
  background-color: #262729;
  color: #fff;
}
