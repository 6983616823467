@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.input {
  box-shadow: none !important;
  min-height: 52px;
  border-radius: 4px !important;

  :global {
    .ant-input {
      font-size: 16px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #f7f7f7;
      box-shadow: none !important;
    }
  }
}