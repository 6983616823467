@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.wrapper {
  word-spacing: -4px;

  color: #F7F7F7;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin: 15px 0;
}