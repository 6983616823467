@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.styledBlockWrapper {
  width: 100%;
  height: 52px;
  border-radius: 8px;
  padding: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
}