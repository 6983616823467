@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.wrapper {
  width: 100%;
  min-height: 52px;

  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #262729;
}

.dataWrapper {
  // margin-left: 10px;
  text-overflow: ellipsis;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.3px;
}

.nameColor {
  color: #f7f7f7;
}

.incrementorColor {
  color: #b6bac3;
}

.black {
  background-color: #18191c;
}

.white {
  background-color: #262729;
}
