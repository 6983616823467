@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Poppins:wght@400;500;600&display=swap');

body {
}

.backgroundImage {
    background: #19191B url("../../assets/lines.svg") no-repeat;
    background-color: rgb(14, 16, 19);
    background-size: cover;
    min-height: 100%;
    width: 100vw;
    margin: 0;
}

.header {
    display: flex;
    height: 112px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
}

.logoSection {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    flex: 1 0 0;
}

.textLogo {
    color: var(--black-haze-50, #F7F7F7);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.contentCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.contentText {
    color: var(--black-haze-200, #DCDCDC);
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
    width: 86%;
    overflow: hidden;
}

.discordContainer {
    display: flex;
    padding: 24px;
    /* flex-direction: column; */
    align-items: flex-start;
    gap: 24px;
    border-radius: 8px;
    background: var(--black-haze-950, #292929);
}

.discordContainerPortaler {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--black-haze-900, #3D3D3D);
}

.discordContainerText {
    display: flex;
    align-items: center;
    gap: 96px;
}

.discordColumnContainer{
    display: flex;
    height: 62px;
    padding: 4px 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.discordHeading {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.discordName {
    color: var(--black-haze-100, #EFEFEF);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.discordServerStatus {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
}

.discordOnline {
    display: flex;
    align-items: center;
    gap: 8px;
}

.discordCirle {
    color: #3AA93F;
    font-family: Font Awesome 6 Pro;
    font-size: 6px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.discordOnline {
    color: var(--black-haze-400, #989898);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.discordColumnButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 24px;
}

.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    position: fixed;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.buttonText {
    color: var(--mexican-red-500, #EA4949);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 20px */
    letter-spacing: -0.2px;
}

.discordJoinButton {
    display: flex;
    height: 46px;
    padding: 16px 24px;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: var(--mexican-red-500, #EA4949);
}

.discordTextButton {
    color: var(--black-haze-50, #F7F7F7);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    letter-spacing: -0.2px;
}

@media (max-width: 391px) {
    .contentText {
        font-size: 19px;
        width: 60%;
    }
}

@media (max-width: 490px) {
    .discordContainer {
        padding: 17px;
        gap: 14px;
    }
    .discordContainerPortaler {
        padding: 11px;
    }
    .discordName {
        font-size: 15px;
    }
    .discordOnline {
        font-size: 9px;
    }
    .discordColumnContainer{
        padding: 0px 0px;
    }
    .discordJoinButton {
        height: 20px;
        padding: 16px 24px;
    }
    .discordContainerText {
        gap: 66px;
    }
    .discordServerStatus {
        gap: 10px;
        height: 32px;
    }
    .discordTextButton {
        font-size: 11px;
    }
    .buttonText {
        font-size: 15px;
    }
}

@media (max-width: 416px) {
    .discordContainer {
        padding: 11px;
        gap: 14px;
    }
    .discordContainerText {
        gap: 36px;
    }
    
}

@media (max-width: 365px) {
    .discordContainer {
        padding: 6px;
        gap: 14px;
    }
    .discordContainerText {
        gap: 26px;
    }
    .discordContainerPortaler {
        padding: 6px;
    }
    .discordJoinButton {
        padding: 12px 12px;
    }
    .contentText {
        font-size: 15px;
        width: 60%;
    }
}
