.layoutHeader {
  height: 10vh;
  position: fixed;
  padding: 0;

  background-color: transparent;
  color: #fff;
  z-index: 100;
  right: 0;
  transition: all 0.17s ease-in-out;
}

.layoutContent {
  height: 80vh;
  padding: 0;

  overflow-y: auto;
  overflow-x: hidden;
  background-color: #262729;
  color: #fff;
}

.layoutSidebar {
  // Width specified in Sider component itself sadly
  //width: 20vw;
  height: 100vh;
  padding: 0;

  overflow-y: auto;
  overflow-x: hidden;
  color: #fff;
}

.layoutFooter {
  height: 10vh;
  padding: 0;

  overflow-y: auto;
  overflow-x: hidden;
  background-color: #262729;
  color: #fff;
}
