* {
  user-select: none;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.background {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.contentHidden {
  overflow-y: auto;
  width: 0;
  opacity: 0;

  //Sidebar open -> closed
  transition: all 0.17s linear;
}

//Used for both navBar and contentBar
.centeredBar {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.sidebarContentWrapper {
  overflow-x: hidden;
  width: 340px;

  padding: 0 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #18191C;
  opacity: 1;

  //Sidebar closed -> open
  transition: all 0.17s linear;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.sidebarNavigationWrapper {
  overflow-y: hidden;
  min-width: 74px;

  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: #18191C;
  border-left: 1px solid #292929;
}

////////////////////////////////////////////
//Tabs animations and styling, not sidebar//
////////////////////////////////////////////
.sidebarTabs {
  width: 55px;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.sidebarButtonWrapper {
  height: 55px;
  width: 55px;
  position: relative;
  border-radius: 4px;
  display: flex;
  visibility: visible;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
  cursor: pointer;

  &:hover {
    background-color: #262729;
    //TabBack active <-> inactive
    transition: all 0.25s linear;
  }

  &:hover .tabDot {
    opacity: 1;
  }
}

.tabDot {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #b6bac3;
  bottom: -2px;
  opacity: 0;
  //Nothing <-> Dot transition
  transition: all 0.15s linear;
}

.tabDotActive {
  width: 30px;
  height: 4px;
  opacity: 1;
  border-radius: 8px;
  //Dot <-> Line transition
  transition: all 0.15s linear;
}

.tabActive {
  background-color: #262729;
  //TabBack active <-> inactive
  transition: all 0.15s linear;
}

//////////////////
//UserBlock kekw//
//////////////////
.userBlockWrapper {
  display: block;
  position: fixed;
  left: 24px;
  bottom: 24px;
  min-width: 250px;
  width: 16vw;
  transition: all 0.5s linear;
}