@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.userBlockWrapper {
  display: flex;
  flex-direction: column;

  margin-bottom: 24px;
}

.userBlockElementsWrapper {
  height: 60px;
  width: 100%;

  display: flex;
  flex-direction: row;

  margin: 12px 0;
}

.userInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text {
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 15px;
}

.username {
  color: #F7F7F7;
}

.discriminator {
  color: #717886;
}