@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.input {
  width: 100%;
  :global {
    .ant-select-selector {
      min-height: 52px !important;
      box-shadow: none !important;
      background-color: #18191C;
      border-radius: 4px !important;
    }

    .ant-select-selection-search-input {
      min-height: 52px !important;
    }

    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    .rc-virtual-list {
      align-items: center !important;
    }

    .rc-virtual-list-holder-inner {
      align-items: center !important;
    }
  }
}