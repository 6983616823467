.background {
  width: 100%;
  height: 100%;

  background-color: #2f3032;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.16px;

  flex-shrink: 0;
}

.footerInner {
  display: flex;

  width: 95%;

  flex-direction: row;
  justify-content: space-between;
}

.innerStyles {
  display: flex;
  flex-direction: row;
}
