.mapContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.mapInfo {
  position: fixed;
  padding: 5px;
  z-index: 100;
}

.mapGraph {
  width: 100%;
  height: 100%;
}