.headerWrapper {
  flex: 1;
  //height: 10vh;
  margin: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  transition: all 0.5s linear;
}
